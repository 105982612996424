import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Link,
} from "@react-pdf/renderer";
import KarmaRegular from "assets/fonts/Karma-Regular.ttf";
import KarmaSemiBold from "assets/fonts/Karma-SemiBold.ttf";
import KarmaBold from "assets/fonts/Karma-Bold.ttf";
import NotoSansRegular from "assets/fonts/arial-unicode-ms.ttf";
import NotoSansBold from "assets/fonts/arial-unicode-ms-bold.ttf";
import { columnHeaderFacebookDaily } from "utils/data";
import ImageCardPdf from "./ImageCardPdf";
import Card from "./Card";

Font.register({
  family: "Roboto",
  fonts: [
    { src: NotoSansRegular, fontWeight: 400 },
    { src: NotoSansBold, fontWeight: 600 },
  ],
});

const styles = StyleSheet.create({
  page: { padding: 30 },
  section: { marginBottom: 10 },
  row: {
    flexDirection: "row",
    borderBottom: "1px solid #e0e0e0",
  },
  cell: {
    padding: 1,
    fontSize: "9px",
    textAlign: "center",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 55,
  },
  headerCell: {
    fontWeight: "bold",
    backgroundColor: "#084B83",
    color: "#FFFFFF",
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
    fontWeight: "bold",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 12,
    marginBottom: 5,
  },
  centerContent: {
    alignItems: "center",
  },
  topPostsWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 15,
  },
  headingTextNew: {
    fontSize: "12px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    color: "#4F4F4F",
    fontWeight: "bold",
    paddingHorizontal: 4,
    paddingVertical: 2,
  },
});

const PdfSmarDashboard = ({
  platform,
  selectedColumns,
  data,
  selectedFrequency,
  type,
}) => {
  // Random color logic commented out
  const [formattingData, setFormattingData] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      const newFormattingData = data
        .map((eachSmarData) =>
          eachSmarData.data.map((tableData) => tableData.smargroup_data)
        )
        .flat(2);
      setFormattingData(newFormattingData);
    }
  }, [data]);

  const randomColour = (field, value, groupIndex) => {
    if (value === 0) {
      return "#FFFFFF";
    }
    if (formattingData.length > 0) {
      const groupData = formattingData.filter(
        (item) => item.smar_group_id === groupIndex
      );
      const sortedData = [...groupData].sort((a, b) => b[field] - a[field]);
      let index = sortedData.findIndex((x) => x[field] === value);
      if (index >= 7) {
        return "#FFFFFF";
      }
      const colors = [
        "#70db70",
        "#85e085",
        "#99e699",
        "#adebad",
        "#c2f0c2",
        "#d6f5d6",
        "#ebfaeb",
      ];
      return colors[index];
    }
    return "#FFFFFF";
  };
  function getDateDifference(startDate, endDate) {
    // Create Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the time difference in milliseconds
    const timeDifference = end.getTime() - start.getTime();

    // Convert to different units
    const millisecondsInDay = 1000 * 60 * 60 * 24;
    const daysDifference = Math.floor(timeDifference / millisecondsInDay);
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));

    return {
      days: daysDifference,
      hours: hoursDifference,
      minutes: minutesDifference,
      milliseconds: timeDifference,
    };
  }

  const getColumnWidth = (columnId) => {
    const widths = {
      position: "5%",
      page__page_name: "23%",
      page__political_party__name: "12%",
      score: "9%",
      total_posts: "9%",
      total_shares: "12%",
      num_posts_in_top: "9%",
      max_shares: "10%",
      median_shares: "10%",
      verification_status: "8%",
      followers_count: "11%",
    };
    return widths[columnId] || "10%";
  };
  const formatNumber = (num) => {
    return num.toLocaleString("en-IN", { maximumFractionDigits: 0 });
  };
  const renderHeader = (id) => {
    return (
      <View style={styles.row}>
        {(type !== "preview" ? selectedColumns[id] : selectedColumns).map(
          (column, index) => {
            const columnWidth = getColumnWidth(column.id);

            return (
              <View
                key={column.id}
                style={[
                  styles.cell,
                  styles.headerCell,
                  { width: columnWidth, borderRight: "1px solid #e0e0e0" },
                  index === 0 && { borderLeft: "1px solid #e0e0e0" },
                ]}
              >
                <Text>{column.label}</Text>
              </View>
            );
          }
        )}
      </View>
    );
  };

  const renderRow = (item, rowIndex, id, groupIndex) => (
    <View style={styles.row} key={rowIndex} wrap={false}>
      {(type !== "preview" ? selectedColumns[id] : selectedColumns).map(
        (column, cellIndex) => {
          const columnWidth = getColumnWidth(column.id);

          const cellStyle = [
            styles.cell,
            { width: columnWidth, borderRight: "1px solid #e0e0e0 !important" },
            cellIndex === 0 && { borderLeft: "1px solid #e0e0e0" },
          ];

          const shouldColorCode = [
            "score",
            "total_posts",
            "total_shares",
            "num_posts_in_top",
            "max_shares",
            "median_shares",
            "followers_count",
          ].includes(column.id);
          const backgroundColor = shouldColorCode
            ? randomColour(column.id, item[column.id], groupIndex)
            : "transparent";

          return (
            <View key={column.id} style={cellStyle}>
              {column.id === "verification_status" ? (
                <Text style={{ fontFamily: "Roboto", fontSize: "9px" }}>
                  {item[column.id] ? "True" : "False"}
                </Text>
              ) : column.id === "followers_count" ? (
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "9px",
                    paddingHorizontal: 7,
                    paddingVertical: 3,
                    borderRadius: 10,
                    backgroundColor,
                  }}
                >
                  {item[column.id] ? formatNumber(item[column.id]) : 0}
                </Text>
              ) : column.id === "page__page_name" ? (
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "9px",
                    paddingHorizontal: 7,
                    paddingVertical: 3,
                    borderRadius: 10,
                    backgroundColor,
                  }}
                >
                  <Link
                    src={
                      platform === 3
                        ? `https://youtube.com/channel/${item.page__page_id}`
                        : `https://facebook.com/${item.page__page_id}`
                    }
                    style={{
                      textDecoration: "none",
                      borderBottom: "none",
                      color: "#0000FF",
                    }}
                  >
                    {item[column.id]}
                  </Link>
                </Text>
              ) : (
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "9px",
                    paddingHorizontal: 7,
                    paddingVertical: 3,
                    borderRadius: 10,
                    backgroundColor,
                  }}
                >
                  {formatNumber(item[column.id])}
                </Text>
              )}
            </View>
          );
        }
      )}
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>{`${
          selectedFrequency === "d"
            ? "Daily Analysis"
            : selectedFrequency === "w"
            ? "Weekly Analysis"
            : selectedFrequency === "m"
            ? "Monthly Analysis"
            : ""
        }`}</Text>
        {data.map((eachSmarData, index) => (
          <View key={index} break={index !== 0}>
            {eachSmarData.data.map((tableData, idx) => {
              return (
                <View key={idx} style={styles.centerContent} break={idx !== 0}>
                  <Text
                    style={{
                      fontSize: "16px",
                      marginVertical: "10px",
                      textAlign: "center",
                    }}
                  >
                    {tableData.smar_group_name}
                  </Text>
                  <View style={styles.subtitle}>
                    {tableData.start_date !== tableData.end_date &&
                    selectedFrequency !== "d" ? (
                      <Text
                        style={{ fontSize: "12px" }}
                      >{`(${tableData.start_date} - ${tableData.end_date})`}</Text>
                    ) : (
                      <Text
                        style={{ fontSize: "12px" }}
                      >{`(${tableData.start_date})`}</Text>
                    )}
                  </View>
                  {renderHeader(tableData.smargroup_data[0]?.smar_group_id)}
                  {tableData.smargroup_data.map((item, rowIndex) =>
                    renderRow(
                      item,
                      rowIndex,
                      tableData.smargroup_data[0]?.smar_group_id,
                      tableData.smargroup_data[0]?.smar_group_id
                    )
                  )}
                  {(getDateDifference(tableData.start_date, tableData.end_date)
                    .days <= 7 ||
                    selectedFrequency === "d" ||
                    selectedFrequency === "w") &&
                    tableData.top_post?.length > 0 && (
                      <View key={index} break style={styles.centerContent}>
                        <Text
                          style={{
                            fontSize: "12px",
                            marginVertical: "5px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Top Posts
                        </Text>

                        <View style={styles.topPostsWrapper}>
                          {tableData.top_post?.map((imgVal) => (
                            // <ImageCardPdf
                            // key={imgVal.post_id}
                            // image={imgVal.full_picture}
                            // shares={imgVal.shares}
                            // name={imgVal.page_name}
                            // type="image"
                            // />
                            <Card
                              key={imgVal.post_id}
                              title={imgVal.page_name}
                              description={imgVal.message}
                              shares={imgVal.shares}
                              likes={imgVal.likes}
                              comments={imgVal.comments}
                              postLink={imgVal.post_link ?imgVal.post_link:imgVal.post_id}
                              platform={imgVal.post_link ?  "facebook" : "youtube"}
                              reportType={tableData.report_type}
                              type={"smarDashboard"}
                              imageUrl={imgVal.full_picture}
                            />
                          ))}
                        </View>
                      </View>
                    )}
                </View>
              );
            })}
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default PdfSmarDashboard;
