import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Link,
  Font,
} from "@react-pdf/renderer";
import KarmaRegular from "assets/fonts/Karma-Regular.ttf";
import KarmaSemiBold from "assets/fonts/Karma-SemiBold.ttf";
import KarmaBold from "assets/fonts/Karma-Bold.ttf";
import arialHindi from "assets/fonts/arial-unicode-ms.ttf";

Font.register({
  family: "Karma",
  fonts: [
    { src: KarmaRegular, fontWeight: 400 },
    { src: KarmaSemiBold, fontWeight: 700 },
    { src: KarmaBold, fontWeight: 700 },
  ],
});
Font.register({
  family: "arialHindi",
  fonts: [
    { src: arialHindi, fontWeight: 400 },
  ],
});
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#E4E4E4",
    padding: 10,
  },
  cardContainer: {
    width: "33%",
    padding: 5,
    height: 220, // Fixed height for each card container (840/3 = 280 for 3 rows)
  },
  cardContainerSmarDashboard:{
    width: "25%",
    padding: 3,
    height: 240, // Fixed height for each card container (840/3 = 280 for 3 rows)
  },
  card: {
    backgroundColor: "white",
    border: "1px solid #D3D3D3",
    borderRadius: 5,
    flexDirection: "column",
    alignItems: "center",
    height: 220, // Slightly less than container to account for padding
  },
  imageContainer: {
    width: "100%",
    height: 120,
    backgroundColor: "#D3D3D3",
    overflow: "hidden", // Ensure image doesn't overflow
  },
  imageContainerSmarDashboard: {
    width: "100%",
    height: 140, // Consistent height for smarDashboard
    backgroundColor: "#F0F0F0",
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover", // Use 'cover' for both types
  },
  card: {
    backgroundColor: "white",
    border: "1px solid #D3D3D3",
    borderRadius: 5,
    flexDirection: "column",
    height: 220,
    justifyContent: "space-between", // This ensures content is spread out vertically
  },
  contentContainer: {
    width: "100%",
    padding: 10,
    flexGrow: 1, // This allows the content to grow and push the stats to the bottom
    display: 'flex',
    flexDirection: 'column',
  },
  textContent: {
    flexGrow: 1, // This allows the text content to fill available space
  },
  descriptionContainer: {
    flexGrow: 1, // This will make the description container take up all available space
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
    color: "black",
    textDecorationColor: "white",
    fontFamily: "NewRoboto",
    fontWeight: 700,
  },
  description: {
    fontSize: 8,
    marginBottom: 10,
    textAlign: "left",
    color: "#666",
    textDecorationColor: "white",
    flexGrow: 1,
    fontFamily: "NewRoboto",
    fontWeight: 400,
  },
  nonTailwindDescription: {
    fontSize: 8,
    marginBottom: 5,
    textAlign: "left",
    color: "#666",
    textDecorationColor: "white",
    flexGrow: 1,
    fontFamily: "arialHindi",
    fontWeight: 400,
  },

  stats: {
    flexDirection: "row",
    gap: 10,
    justifyContent: "space-between",
    width: "100%",
    borderTopWidth: 1,
    borderTopColor: "#E4E4E4",
    paddingTop: 5,
  },
  statsInstagram: {
    flexDirection: "row",
    gap: 20,
    justifyContent: "center", // Center alignment for Instagram
    width: "100%",
    borderTopWidth: 1,
    borderTopColor: "#E4E4E4",
    paddingTop: 5,
  },
  stat: {
    flexDirection: "column",
    alignItems: "center",
  },
  statValue: {
    fontSize: 10,
    fontWeight: "bold",
    color: "black",
    textDecorationColor: "white",
    fontFamily: "NewRoboto",
    fontWeight: 700,
  },
  statLabel: {
    fontSize: 8,
    color: "#666",
    textDecorationColor: "white",
    fontFamily: "NewRoboto",
    fontWeight: 400,
  },
});

const Card = ({
  title,
  description,
  shares,
  likes,
  comments,
  imageUrl,
  postLink,
  platform,
  reportType,
  type
}) => {
  const truncateDescription = (text, maxLength = 120) => {
    // Remove newline characters and replace with spaces
    const singleLineText = text.replace(/\n/g, " ");
    // Trim any extra spaces
    const trimmedText = singleLineText.replace(/\s+/g, " ").trim();

    if (trimmedText.length <= maxLength) return trimmedText;
    return trimmedText.slice(0, maxLength) + "...";
  };
  const getImageContainerStyle = () => {
    return type === "smarDashboard" 
      ? styles.imageContainerSmarDashboard 
      : styles.imageContainer;
  };
  
  return (
    <View style={type==="smarDashboard"?styles.cardContainerSmarDashboard:styles.cardContainer} wrap={false}>
      <Link src={type==="smarDashboard"? platform === "youtube" ?`https://www.youtube.com/watch?v=${postLink}`:postLink: postLink}>
        <View style={styles.card}>
        <View style={getImageContainerStyle()}>
            {imageUrl && (
              <Image 
                style={styles.image} 
                src={imageUrl} 
              />
            )}
          </View>
          <View style={styles.contentContainer}>
            <View style={styles.textContent}>
              <Text style={styles.title}>{title}</Text>
              <Text
                style={
                  reportType === 9 || reportType === 10 || reportType === 11
                    ? styles.description
                    : styles.nonTailwindDescription
                }
              >
                {type==="smarDashboard"?truncateDescription(description,50):truncateDescription(description)}
              </Text>

            </View>
            <View
              style={
                platform === "instagram" ? styles.statsInstagram : styles.stats
              }
            >
              {platform !== "instagram" && (
                <View style={styles.stat}>
                  <Text style={styles.statLabel}>Shares</Text>
                  <Text style={styles.statValue}>
                    {shares?.toLocaleString()}
                  </Text>
                </View>
              )}

              <View style={styles.stat}>
                <Text style={styles.statLabel}>Likes</Text>
                <Text style={styles.statValue}>{likes?.toLocaleString()}</Text>
              </View>
              <View style={styles.stat}>
                <Text style={styles.statLabel}>Comments</Text>
                <Text style={styles.statValue}>{comments?.toLocaleString()}</Text>
              </View>
            </View>
          </View>
        </View>
      </Link>
    </View>
  );
};

export default Card;
