import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import ReactGA from "react-ga4";

import PrivateRoute from "components/PrivateRoute";
import AlertBar from "components/AlertBar";
import AppLayout from "layout";

//context
import { AuthContext } from "contexts/AuthContext";
import NavLinks from "utils/NavLinks";
import { useAdvancedAnalytics } from "hooks/useAdvanedAnalytics";
import { SmarDashboardRedirect } from "hooks/useSmarDashboardRedirect";

const LoginView = React.lazy(() => import("pages/auth/login"));
const MainView = React.lazy(() => import("pages"));
const ErrorView = React.lazy(() => import("pages/error"));

//UserView
const ViewUsers = React.lazy(() => import("pages/app/users"));
const ViewPeople = React.lazy(() => import("pages/app/users/people"));

//SocialPageView
const ViewSocialPages = React.lazy(() => import("pages/app/social-pages"));
const ViewFacebookPage = React.lazy(() =>
  import("pages/app/social-pages/facebook")
);
const ViewTwitterPage = React.lazy(() =>
  import("pages/app/social-pages/twitter")
);
const ViewYouTubePage = React.lazy(() =>
  import("pages/app/social-pages/youtube")
);
const ViewInstagramPage = React.lazy(() =>
  import("pages/app/social-pages/instagram")
);
const ViewOnDemandScraping = React.lazy(() =>
  import("pages/app/social-pages/on-demand-scraping")
);

//SmarGroup
const ViewSmarGroup = React.lazy(() => import("pages/app/smar-group"));

//ReportsView
const ViewReportsPage = React.lazy(() => import("pages/app/reports-page"));
const ViewReportInfo = React.lazy(() =>
  import("pages/app/reports-page/reports-info")
);
const ViewGeneratedReports = React.lazy(() =>
  import("pages/app/reports-page/generated_reports")
);

//ContentTaggingView
const ViewContentTaggingPage = React.lazy(() =>
  import("pages/app/content-tagging")
);
const ViewContentTaggingOverview = React.lazy(() =>
  import("pages/app/content-tagging/overview")
);
const ViewContentTagging = React.lazy(() =>
  import("pages/app/content-tagging/tagging")
);
const ViewContentTaggingDashboard = React.lazy(() =>
  import("pages/app/content-tagging/dashboard")
);
const ViewMpReport = React.lazy(() =>
  import("pages/app/content-tagging/mp-reports")
);

//MediaMonitoringView
const ViewMediaMonitoringPage = React.lazy(() =>
  import("pages/app/media-monitoring")
);
const ViewElectronicMedia = React.lazy(() =>
  import("pages/app/media-monitoring/electronic-media")
);
const ViewTwitter = React.lazy(() =>
  import("pages/app/media-monitoring/twitter")
);
const ViewMediaDashboard = React.lazy(() =>
  import("pages/app/media-monitoring/dashboard")
);
const ViewPrintAndDigitalMedia = React.lazy(() =>
  import("pages/app/media-monitoring/print_digital_media")
);
const ViewMediaMonitoringReports = React.lazy(() =>
  import("pages/app/media-monitoring/media_monitoring_reports")
);
const ViewContentLogs = React.lazy(() =>
  import("pages/app/media-monitoring/content_logs")
);
//Campaign Ads View
const ViewCampaignAds = React.lazy(() =>
  import("pages/app/media-monitoring/ad-campaign")
);

//Smar Dashboard View
const ViewSmarDashboard = React.lazy(() => import("pages/app/smar-dashboard"));

function App() {
  const [initial, setInitial] = useState(0);
  const [userIdFlag, setUserIdFlag] = useState(true);
  const [activateLog, setActivateLog] = useState(false);
  const { user } = useContext(AuthContext);
  const { smarDashboardSession } = useAdvancedAnalytics(
    activateLog,
    setActivateLog,
    user?.pk
  );

  useEffect(() => {
    if (initial === 0) {
      setInitial(1);
      if (user) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
        ReactGA.set({
          user_id: user.pk,
        });
        ReactGA.set({
          user_info: user.pk,
        });
      } else {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
      }
    }
  }, [user]);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginView />} />
        <Route path="/password_reset/confirm/*" element={<LoginView isResetPassword ={true}/>} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<MainView />} />
          <Route
            path="/app"
            element={<AppLayout setActivateLog={setActivateLog} />}
          >
            <Route path="users" element={<ViewPeople />} />
            {/* <Route path="people" element={<ViewPeople />} />
            </Route> */}
            <Route path="pages" element={<ViewSocialPages />}>
              <Route path="facebook" element={<ViewFacebookPage />} />
              <Route path="twitter" element={<ViewTwitterPage />} />
              <Route path="youtube" element={<ViewYouTubePage />} />
              <Route path="instagram" element={<ViewInstagramPage />} />
              <Route
                path="on-demand-scraping"
                element={<ViewOnDemandScraping />}
              />
            </Route>
            <Route path="smar" element={<ViewSmarGroup />} />
            <Route path="reports" element={<ViewReportsPage />}>
              <Route path="generate_report" element={<ViewReportInfo />} />
              <Route
                path="generated_reports"
                element={<ViewGeneratedReports />}
              />
            </Route>
            <Route path="content-tagging" element={<ViewContentTaggingPage />}>
              <Route path="overview" element={<ViewContentTaggingOverview />} />
              <Route path="tagging" element={<ViewContentTagging />} />
              <Route
                path="dashboard"
                element={<ViewContentTaggingDashboard />}
              />
              <Route path="mp-reports" element={<ViewMpReport />} />
            </Route>
            <Route
              path="media-monitoring"
              element={<ViewMediaMonitoringPage />}
            >
              <Route
                path="electronic-media"
                element={<ViewElectronicMedia />}
              />
              <Route path="twitter" element={<ViewTwitter />} />
              {/*<Route path="dashboard" element={<ViewMediaDashboard />} />*/}
              <Route
                path="dashboard"
                element={<ViewMediaMonitoringReports />}
              />
              <Route
                path="print-and-digital-media"
                element={<ViewPrintAndDigitalMedia />}
              />
              <Route path="content-logs" element={<ViewContentLogs />} />
              <Route path="campaign-ads" element={<ViewCampaignAds />} />
            </Route>
            <Route
              path="smar-dashboard"
              element={<Navigate to="/app/smar-dashboard/facebook" replace />}
            />
            <Route
              path="smar-dashboard/facebook"
              element={
                <ViewSmarDashboard
                  tab={"facebook"}
                  setActivateLog={setActivateLog}
                />
              }
            />
            <Route
              path="smar-dashboard/facebook/*"
              element={
                <SmarDashboardRedirect
                  tab={"facebook"}
                  setActivateLog={setActivateLog}
                />
              }
            />
            <Route
              path="smar-dashboard/youtube"
              element={
                <ViewSmarDashboard
                  tab={"youtube"}
                  setActivateLog={setActivateLog}
                />
              }
            />
            <Route
              path="smar-dashboard/youtube/*"
              element={
                <SmarDashboardRedirect
                  tab={"youtube"}
                  setActivateLog={setActivateLog}
                />
              }
            />
            <Route
              path="smar-dashboard/generated-reports"
              element={
                <ViewSmarDashboard
                  tab={"generated-reports"}
                  setActivateLog={setActivateLog}
                />
              }
            />
            <Route
              path="smar-dashboard/generated-reports/*"
              element={
                <SmarDashboardRedirect
                  tab={"generated-reports"}
                  setActivateLog={setActivateLog}
                />
              }
            />
          </Route>
        </Route>
        <Route path="*" element={<ErrorView />} />
      </Routes>
      <AlertBar />
    </Router>
  );
}

export default App;
