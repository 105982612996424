import { LogPageSession } from "apis/componentsAction/page-session-log";
import { AuthContext } from "contexts/AuthContext";
import React, { useState, useRef, useContext, useEffect, useCallback } from "react";


export const useAdvancedAnalytics = (activateLog, setActivateLog,id) => {
    const [smarDashboardSession, setSmarDashboardSession] = useState(null);
    const [userId, setUserId] = useState(id);
    const [checkSession, setCheckSession] = useState(false);
    const [isUserActive, setIsUserActive] = useState(true);
    const sessionTimerRef = useRef(null);
    
    const inactivityTimerRef = useRef(null);
    const lastActivityTimeRef = useRef(Date.now());
    
    const { user } = useContext(AuthContext);
    
    const INACTIVITY_TIMEOUT = 5 * 60 * 1000; // 5 minutes of inactivity
    const INACTIVITY_TIMEOUT_SECONDS = 5 * 60; // 5 minute of inactivity
    const convertToIST = (date) => {
        const istDate = new Date(date);
        istDate.setHours(istDate.getHours() + 5);
        istDate.setMinutes(istDate.getMinutes() + 30);
        return istDate;
    };
    const submitTabSwitchSession = useCallback(() => {
        
        setSmarDashboardSession((prevSession) => {
            if (!prevSession) return null;
            
            const now = convertToIST(new Date());
            
            const updatedPages = { ...prevSession.pages };
            Object.keys(updatedPages).forEach((page) => {
                if (updatedPages[page].active) {
                    const duration = (now - new Date(updatedPages[page].start_time)) / 1000;
                    updatedPages[page].total_duration += duration;
                    updatedPages[page].active = false;
                }
            });
            
            const sessionPayload = {
                session: {
                    start_time: prevSession.start_time,
                    end_time: now.toISOString(),
                    duration: (now - new Date(prevSession.start_time)) / 1000,
                    user: userId,
                    //status: 'tab_switch'
                },
                pages: updatedPages,
            };
            
            // Submit the session
            submitSession(sessionPayload);
            setSmarDashboardSession(null)
            
            return null;
        });
        
        setIsUserActive(false);
    }, [userId]);
 // Reset inactivity timer and start a new session if inactive
 const resetInactivityTimer = useCallback(() => {
    
    // Clear existing timer
    if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
    }
    
    // Update last activity time
    lastActivityTimeRef.current = Date.now();
    
    // If user was inactive, start a new session
    if (!isUserActive) {
        startNewSession();
    }
    
    // Set new inactivity timer
    inactivityTimerRef.current = setTimeout(handleInactivity, INACTIVITY_TIMEOUT);
}, [isUserActive]);
// Handle user inactivity
const handleInactivity = useCallback(() => {   
    
    setSmarDashboardSession((prevSession) => {
        if (!prevSession) return null;
        
        const now = convertToIST(new Date());
        
        // Finalize all active pages
        const updatedPages = { ...prevSession.pages };
        Object.keys(updatedPages).forEach((page) => {
            if (updatedPages[page].active) {
                // Calculate remaining duration for the active page
                const duration = Number(((now - new Date(updatedPages[page].start_time)) / 1000 - INACTIVITY_TIMEOUT_SECONDS).toFixed(2));
                updatedPages[page].total_duration += duration;
                updatedPages[page].active = false;
            }
        });
        
        const sessionPayload = {
            session: {
                start_time: prevSession.start_time,
                end_time: now.toISOString(),
                duration: Number(((now - new Date(prevSession.start_time)) / 1000 - INACTIVITY_TIMEOUT_SECONDS).toFixed(2)), 
                user: userId,
            },
            pages: updatedPages,
        };
        
        // Submit the session
       isUserActive&& submitSession(sessionPayload);
        
        return null;
    });
    setIsUserActive(false);
    // Clear any existing timers
    if (sessionTimerRef.current) {
        clearTimeout(sessionTimerRef.current);
    }
    if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
    }
}, [userId]);
 // Start a new session
 const startNewSession = useCallback(() => {
    const pathname = window.location.pathname;
    
    const now = convertToIST(new Date());
    if(isSmarDashboardPath(pathname)){
      setSmarDashboardSession({
        start_time: now.toISOString(),
        pages: {
            [pathname]: {
                section: extractSection(pathname),
                active: true,
                start_time: now,
                total_duration: 0,
            }
        }
    });  
    }
    
    // Set user as active
    setIsUserActive(true);
}, []);

    useEffect(() => {
        // Event listeners to track user activity
        const activityEvents = ['mousedown', 'keydown', 'mousemove', 'wheel'];
        
        const trackActivity = () => {
            resetInactivityTimer();
        };
        
        // Add event listeners
        activityEvents.forEach(event => {
            window.addEventListener(event, trackActivity);
        });
        
        // Initial inactivity timer setup
        inactivityTimerRef.current = setTimeout(handleInactivity, INACTIVITY_TIMEOUT);
        
        // Cleanup
        return () => {
            // Remove event listeners
            activityEvents.forEach(event => {
                window.removeEventListener(event, trackActivity);
            });
            
            // Clear timers
            if (inactivityTimerRef.current) {
                clearTimeout(inactivityTimerRef.current);
            }
            if (sessionTimerRef.current) {
                clearTimeout(sessionTimerRef.current);
            }
        };
    }, [resetInactivityTimer, handleInactivity]);
    
    const isSmarDashboardPath = (pathname) => pathname.includes('smar-dashboard');
   
    const extractSection = (pathname) => {
        if (pathname.includes('/facebook/tabular')) return 'Facebook Tabular Dashboard';
        if (pathname.includes('/facebook/graphical')) return 'Facebook Graphical Dashboard';
        if (pathname.includes('/facebook/page-analytics')) return 'Facebook Page Level Dashboard';
        if (pathname.includes('/facebook')) return 'Facebook Dashboard';
        if (pathname.includes('/youtube/tabular')) return 'YouTube Tabular Dashboard';
        if (pathname.includes('/youtube/graphical')) return 'YouTube Graphical Dashboard';
        if (pathname.includes('/youtube/page-analytics')) return 'YouTube Page Level Dashboard';
        if (pathname.includes('/youtube')) return 'YouTube Dashboard';
       
        if(pathname.includes('smar-dashboard/generated-reports/preview')) return 'Generated Reports Preview'
        if (pathname.includes('/generated-reports')) return 'Generated Reports';
        return 'SMAR Dashboard';
    };

    const startSmarDashboardSession = useCallback((pathname) => {
        const now = convertToIST(new Date());
        if(isSmarDashboardPath(pathname)){
            setSmarDashboardSession({
                start_time: now.toISOString(),
                pages: {},
            });
    
            trackPageVisit(pathname, now);
        }else{
            setSmarDashboardSession(null)
        }
    }, []);
    
    const submitSession = async (obj) => {    
        const submissionId = `submission_${Date.now()}`;

        // Prevent duplicate submissions
        if (window.__lastSubmissionTime && 
            (Date.now() - window.__lastSubmissionTime) < 1000) {
            return;
        }

        // Convert pages object to an array
        const sanitizedPages = Object.entries(obj.pages).map(([pageUrl, pageData]) => ({
            page_url: pageUrl,
            section: pageData.section,
            duration: pageData.total_duration,
        }));
    
        const sanitizedSession = {
            session: {...obj.session},
            pages: sanitizedPages,
        };
    
        try {
            
            // Set a flag to prevent immediate duplicate submissions
            window.__lastSubmissionTime = Date.now();
            
            const { data, error } = await LogPageSession(sanitizedSession);
            
            if (error) {
                return;
            }
            
            if (data) {
            }
        } catch (err) {
            console.error(`🚨 Exception in submitSession [${submissionId}]`, err, {
                sessionData: sanitizedSession
            });
        }
    };
    const trackPageVisit = useCallback((pathname, currentTime = convertToIST(new Date())) => {
        setSmarDashboardSession((prevSession) => {
            if (!prevSession) return prevSession;
    
            const pageData = { ...prevSession.pages };
            Object.keys(pageData).forEach((page) => {
                if (page !== pathname && pageData[page].active) {
                    // Finalize duration for the previously active page
                    const pausedAt = currentTime;
                    pageData[page].total_duration += (pausedAt - new Date(pageData[page].start_time)) / 1000;
                    pageData[page].active = false;
                }
            });
    
            if (!pageData[pathname]) {
                // New page being visited
                pageData[pathname] = {
                    section: extractSection(pathname),
                    active: true,
                    start_time: currentTime,
                    total_duration: 0,
                };
            } else if (!pageData[pathname].active) {
                // Revisit an existing page
                pageData[pathname].active = true;
                pageData[pathname].start_time = currentTime;
            }
    
            return {
                ...prevSession,
                pages: pageData,
            };
        });
    });
    
    
    const endSmarDashboardSession = useCallback(() => {
        setSmarDashboardSession((prevSession) => {
            if (!prevSession) return null;
    
            const now = convertToIST(new Date());
    
            // Finalize all active pages
            const updatedPages = { ...prevSession.pages };
            Object.keys(updatedPages).forEach((page) => {
                if (updatedPages[page].active) {
                    // Calculate remaining duration for the active page
                    const duration = (now - new Date(updatedPages[page].start_time)) / 1000;
                    updatedPages[page].total_duration += duration;
                    updatedPages[page].active = false; 
                }
            });
    
            const sessionPayload = {
                session: {
                    start_time: prevSession.start_time,
                    end_time: now.toISOString(),
                    duration: (now - new Date(prevSession.start_time)) / 1000, 
                    user: userId
                },
                pages: updatedPages,
            };
    
            // Submit sanitized session data
            submitSession(sessionPayload);
            setSmarDashboardSession(null)
            const pathname=window.location.pathname
            if(!pathname.includes('/login')){
                setCheckSession(true);
            }
             
            return null;
        });
    
        // Clear timeout
        if (sessionTimerRef.current) {
            clearTimeout(sessionTimerRef.current);
        }
    });
     // Add tab switch and blur event listeners
     useEffect(() => {
        const handleVisibilityChange = () => {
            const pathname = window.location.pathname;
            if (document.hidden) {
                
                if(isSmarDashboardPath(pathname)){
                    submitTabSwitchSession();
                }
            }
        };

        const handleWindowBlur = () => {
            const pathname = window.location.pathname;
            if(isSmarDashboardPath(pathname)){
                submitTabSwitchSession();    
            }
            
        };

        // Add event listeners
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('blur', handleWindowBlur);

        // Cleanup
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('blur', handleWindowBlur);
        };
    }, [submitTabSwitchSession]);

    useEffect(() => {
        setUserId(user?.pk);
    }, [user]);
    
    useEffect(() => {
        
        const pathname = window.location.pathname;

        if (isSmarDashboardPath(pathname) && !smarDashboardSession) {
            startSmarDashboardSession(pathname);
        } else if (isSmarDashboardPath(pathname)) {
            trackPageVisit(pathname);
        } else if (!isSmarDashboardPath(pathname) ) {
            endSmarDashboardSession();
        }

        setActivateLog(false);
        setCheckSession(false);
    }, [activateLog]);
    
    useEffect(() => {
        return () => {
            clearTimeout(sessionTimerRef.current);
            endSmarDashboardSession();
        };
    }, []);

    return {
        smarDashboardSession,
    };
};
