import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, Link } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  table: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  reportHeading: {
    height: 35,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headingText: {
    fontSize: "12px",
    textAlign: "center",
    // height: 35,
    verticalAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    fontFamily: "NewRoboto",
    fontWeight: 700,
    backgroundColor: "#fff",
    paddingHorizontal: 2,
    textTransform:'uppercase'
  },
  row: {
    marginTop: '5px',
    flexGrow: 1,
    flexDirection: "row",
    borderBottom: "2px solid #666666",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: "#fff",
    color:'#000',
    // flexBasis: 42,
  },
  row_new: {
    // flexGrow: 1,
    flexDirection: "row",
    // borderBottom: "1px solid #000000",
    // borderLeft: "1px solid #000000",
    // borderRight: "1px solid #000000",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: "#FFFFFF",
    // flexBasis: 42,
  },
  container_rank: {
    width: "5%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 65,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  container_page_name: {
    width: "31%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 65,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  // container_party: {
  //   width: "18%",
  //   textAlign: "center",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   flexDirection: "row",
  //   height: 65,
  //   paddingHorizontal: 6,
  // },
  container_total_score: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 65,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  container_total_posts: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 65,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  container_total_shares: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 65,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  container_top_ten: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 65,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  container_max_shares: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 65,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  container_median_shares: {
    width: "14%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 65,
    paddingHorizontal: 2,
    // borderBottom: "1px solid #000000",
    // borderRight: "1px solid #000000",
  },

  container_rank_new: {
    width: "5%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  container_page_name_new: {
    width: "31%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  // container_party_new: {
  //   width: "18%",
  //   textAlign: "center",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   flexDirection: "row",
  //   height: 65,
  //   paddingHorizontal: 6,
  // },
  container_total_score_new: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  container_total_posts_new: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  container_total_shares_new: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  container_top_ten_new: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  container_max_shares_new: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  container_median_shares_new: {
    width: "14%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    // borderRight: "1px solid #000000",
    // borderBottom: "1px solid #000000",
  },
  container_days_won: {
    width: "7%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    // height: 65,
    paddingHorizontal: 2,
  },
  container_days_won_new: {
    width: "7%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    // height: 65,
    paddingHorizontal: 2,
  },
  column_heading: {
    fontSize: "10px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    fontFamily: "NewRoboto",
    fontWeight: 400,
    textTransform:'uppercase'
  },
  column_heading_new: {
    fontSize: "11px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    color: "#303030",
    fontFamily: "NewRoboto",
    fontWeight: 400,
    paddingHorizontal: 10,
    paddingVertical: 3,
    borderRadius: 10,
  },
  tailwind_scrapping: {
    borderRight: "1px solid #000000",
  },
  column: {
    flexDirection: "column",
  },
});

const instaCheck = [
  148,
  149,
  150,
  151,
  152,
  153,
  154,
  155,
  156,
  157,
  158,
  159,
  160,
  161,
  162,
  163,
  164,
  165,
  166,
  167,
  168,
  169,
  170,
  171,
  172,
  173,
  174,
  175,
  176,
  177,
  178,
  179,
  180,
  181,
  182,
  183,
  184,
  185,
  186,
  187,
  188,
  189,
  190,
  225,
  226,
  227,
  230
];

const PdfTableHeaderNoPartyTailWind = ({
  heading,
  platform,
  data,
  start_date,
  end_date,
  reportGeneratedType,
  reportType,
  fileName,
  reportId,
}) => {

  const [rows, setRows] = useState("");
  const [formattingData, setFormattingData] = useState([]);

  const randomColour = (field, value) => {
    if (value === 0) {
      return "#FFFFFF";
    }
    if (formattingData.length > 0) {
      const data = [...formattingData].sort((firstVal, secondVal) => {
        return secondVal[`${field}`] - firstVal[`${field}`];
      });
      let index_new = data.findIndex((x) => x[`${field}`] === value);
      if (index_new >= 10) {
        return "#FFFFFF";
      }
      // const colors = ["#5FF1F1", "#8EFBFB", "#B0F9F9", "#DAFFFF", "#ECFFFF", "#5cd60c"];
      const colors = [
        "#57BB8A",
        "#69C297",
        "#7CCAA4",
        "#8FD1B1",
        "#A1D9BE",
        "#B4E0CB",
        "#C7E8D8",
        "#D9EFE5",
        "#ECF7F2",
        "#FFFFFF",
      ];
      var random_color = colors[index_new];
      return random_color;
    }
    return "#FFFFFF";
  };

  useEffect(() => {
    if (data.length > 0) {
      setRows(data);
      setFormattingData([...data]);
    } else {
      setRows([]);
      setFormattingData([]);
    }
  }, [data]);

  return (
    <View>
      {fileName !== "Ireland National Parties" ? (
        <View style={styles.reportHeading}>
          <Text style={styles.headingText}>
            {heading} {platform.toLowerCase() === "twitter" ? "Account" : ""}
          </Text>
        </View>
      ) : (
        <></>
      )}

      <View style={styles.row} wrap={false} fixed>
        <View style={[styles.container_rank]}>
          <Text style={styles.column_heading}>Rank</Text>
        </View>
        <View style={[styles.container_page_name]}>
          <Text style={styles.column_heading}>
            {platform.toLowerCase() !== "twitter"
              ? "Page Name"
              : "Account Name"}
          </Text>
        </View>
        <View style={[styles.container_total_score]}>
          <Text style={styles.column_heading}>Score</Text>
        </View>
        <View style={[styles.container_total_posts]}>
          <Text style={styles.column_heading}>
            {platform.toLowerCase() !== "twitter"
              ? "Posts"
              : "Tweets"}
          </Text>
        </View>
        <View style={[styles.container_total_shares]}>
          <Text style={styles.column_heading}>
            {platform.toLowerCase() === "instagram" ? "Likes" : "Shares"}
          </Text>
        </View>
        <View style={[styles.container_median_shares]}>
          <Text style={styles.column_heading}>
            {instaCheck.includes(reportId) ? "Engagements" : "Engagements"}
          </Text>
        </View>
        <View style={[styles.container_top_ten]}>
          <Text style={styles.column_heading}>
            {platform.toLowerCase() !== "twitter"
              ? reportType <= 6 || reportType >= 9
                ? "No. of Posts in Top 10"
                : "No. of Posts in Top 30"
              : reportType <= 6
                ? "No. of Tweets in Top 10"
                : "No. of Tweets in Top 30"}
          </Text>
        </View>
        <View style={[styles.container_max_shares]}>
          <Text style={styles.column_heading}>
            {platform.toLowerCase() === "instagram" ? "Max Likes" : "Max Shares"}
          </Text>
        </View>
       
      </View>
      {rows &&
        rows.map((val, index) => {
          return (
            <View style={styles.row_new} key={index} wrap={false}>
              <View style={[styles.container_rank_new]}>
                <Text style={styles.column_heading_new}>{val?.position}</Text>
              </View>
              {platform.toLowerCase() !== "twitter" ? (
                <View style={[styles.container_page_name_new]}>
                  <Text style={styles.column_heading_new}>
                    {platform.toLowerCase() === 'instagram' ?
                       <Link
                       src={`https://instagram.com/${val.page__page_id}`}
                       style={{
                         textDecoration: "none",
                         borderBottom: "none",
                         color: "#0000FF",
                       }}
                     >
                       {val.page__page_name.split('').join('\u200B')}
                     </Link>
                      // <Text style={{
                      //   color: "#000000",
                      // }}>
                      //   {val.page__page_name.split('').join('\u200B')}
                      // </Text> 
                      :
                      <Link
                        src={`https://facebook.com/${val.page__page_id}`}
                        style={{
                          textDecoration: "none",
                          borderBottom: "none",
                          color: "#0000FF",
                        }}
                      >
                        {val.page__page_name.split('').join('\u200B')}
                      </Link>
                    }
                  </Text>
                </View>
              ) : (
                <View style={[styles.container_page_name_new]}>
                  <Text style={styles.column_heading_new}>
                    {val.page__page_id}
                  </Text>
                </View>
              )}
              <View
                style={[
                  styles.container_total_score_new,
                  // {
                  //   backgroundColor:
                  //     platform.toLowerCase() !== "twitter"
                  //       ? fileName !== "Ireland National Parties" &&
                  //         fileName !== "Ireland National Leaders"
                  //         ? `${randomColour("score", val?.score)}`
                  //         : ""
                  //       : "",
                  // },
                ]}
              >
                <Text
                  style={[
                    styles.column_heading_new,
                    // {
                    //   backgroundColor:
                    //     platform.toLowerCase() !== "twitter"
                    //       ? fileName !== "Ireland National Parties" &&
                    //         fileName !== "Ireland National Leaders"
                    //         ? `${randomColour("score", val?.score)}`
                    //         : ""
                    //       : "",
                    // },
                  ]}
                >
                  {val?.score}
                </Text>
              </View>
              <View
                style={[
                  styles.container_total_posts_new,
                  // {
                  //   backgroundColor:
                  //     platform.toLowerCase() !== "twitter"
                  //       ? fileName !== "Ireland National Parties" &&
                  //         fileName !== "Ireland National Leaders"
                  //         ? `${randomColour("total_posts", val?.total_posts)}`
                  //         : ""
                  //       : "",
                  // },
                ]}
              >
                <Text
                  style={[
                    styles.column_heading_new,
                    // {
                    //   backgroundColor:
                    //     platform.toLowerCase() !== "twitter"
                    //       ? fileName !== "Ireland National Parties" &&
                    //         fileName !== "Ireland National Leaders"
                    //         ? `${randomColour("total_posts", val?.total_posts)}`
                    //         : ""
                    //       : "",
                    // },
                  ]}
                >
                  {platform.toLowerCase() !== "twitter"
                    ? val.total_posts
                    : val.total_tweets}
                </Text>
              </View>
              <View
                style={[
                  styles.container_total_shares_new,
                  // {
                  //   backgroundColor:
                  //     platform.toLowerCase() !== "twitter"
                  //       ? fileName !== "Ireland National Parties" &&
                  //         fileName !== "Ireland National Leaders"
                  //         ? `${randomColour("total_shares", val?.total_shares)}`
                  //         : ""
                  //       : "",
                  // },
                ]}
              >
                <Text
                  style={[
                    styles.column_heading_new,
                    // {
                    //   backgroundColor:
                    //     platform.toLowerCase() !== "twitter"
                    //       ? fileName !== "Ireland National Parties" &&
                    //         fileName !== "Ireland National Leaders"
                    //         ? `${randomColour(
                    //           "total_shares",
                    //           val?.total_shares
                    //         )}`
                    //         : ""
                    //       : "",
                    // },
                  ]}
                >
                  {platform.toLowerCase() !== "twitter"
                    ? val.total_shares.toLocaleString()
                    : val.total_retweets.toLocaleString()}
                </Text>
              </View>
              <View
                style={[
                  styles.container_median_shares_new,
                  // {
                  //   backgroundColor:
                  //     platform.toLowerCase() !== "twitter"
                  //       ? fileName !== "Ireland National Parties" &&
                  //         fileName !== "Ireland National Leaders"
                  //         ? `${randomColour(
                  //           "median_shares",
                  //           val?.median_shares
                  //         )}`
                  //         : ""
                  //       : "",
                  // },
                ]}
              >
                <Text
                  style={[
                    styles.column_heading_new,
                    // {
                    //   backgroundColor:
                    //     platform.toLowerCase() !== "twitter"
                    //       ? fileName !== "Ireland National Parties" &&
                    //         fileName !== "Ireland National Leaders"
                    //         ? `${randomColour(
                    //           "median_shares",
                    //           val?.median_shares
                    //         )}`
                    //         : ""
                    //       : "",
                    // },
                  ]}
                >
                  {platform.toLowerCase() !== "twitter"
                    ? val.total_engagement.toLocaleString()
                    : val.total_engagement.toLocaleString()}
                </Text>
              </View>
              <View
                style={[
                  styles.container_top_ten_new,
                  // {
                  //   backgroundColor:
                  //     platform.toLowerCase() !== "twitter"
                  //       ? fileName !== "Ireland National Parties" &&
                  //         fileName !== "Ireland National Leaders"
                  //         ? `${randomColour(
                  //           "num_posts_in_top",
                  //           val?.num_posts_in_top
                  //         )}`
                  //         : ""
                  //       : "",
                  // },
                ]}
              >
                <Text
                  style={[
                    styles.column_heading_new,
                    // {
                    //   backgroundColor:
                    //     platform.toLowerCase() !== "twitter"
                    //       ? fileName !== "Ireland National Parties" &&
                    //         fileName !== "Ireland National Leaders"
                    //         ? `${randomColour(
                    //           "num_posts_in_top",
                    //           val?.num_posts_in_top
                    //         )}`
                    //         : ""
                    //       : "",
                    // },
                  ]}
                >
                  {platform.toLowerCase() !== "twitter"
                    ? val.num_posts_in_top
                    : val.num_tweets_in_top}
                </Text>
              </View>
              <View
                style={[
                  styles.container_max_shares_new,
                  // {
                  //   backgroundColor:
                  //     platform.toLowerCase() !== "twitter"
                  //       ? fileName !== "Ireland National Parties" &&
                  //         fileName !== "Ireland National Leaders"
                  //         ? `${randomColour("max_shares", val?.max_shares)}`
                  //         : ""
                  //       : "",
                  // },
                ]}
              >
                <Text
                  style={[
                    styles.column_heading_new,
                    // {
                    //   backgroundColor:
                    //     platform.toLowerCase() !== "twitter"
                    //       ? fileName !== "Ireland National Parties" &&
                    //         fileName !== "Ireland National Leaders"
                    //         ? `${randomColour("max_shares", val?.max_shares)}`
                    //         : ""
                    //       : "",
                    // },
                  ]}
                >
                  {platform.toLowerCase() !== "twitter"
                    ? val.max_shares.toLocaleString()
                    : val.max_retweets}
                </Text>
              </View>
             
            </View>
          )

        })}

    </View>
  );
};

export default PdfTableHeaderNoPartyTailWind;