import AXIOS from "services/axios";

export const getPagesList = async (
  platform,
  pageCount,
  radioValue,
  searchPage,
  region,
  state,
  category,
  searchBy
) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/pages/?${
        radioValue ? `page_type=${radioValue}` : ``
      }${pageCount > 1 ? `&page=${pageCount}` : ``}${
        platform ? `&platform=${platform}` : ``
      }${
        searchPage
          ? searchBy === "name"
            ? `&page_name=${searchPage}`
            : `&page_id_str=${searchPage}`
          : ``
      }${region === 2 ? `&region_type=${region}` : ``}${
        region !== 0 ? `&object_id=${state}` : ``
      }${category ? `&page_category=${category}` : ``}`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const getPagesListAll = async (
  platform,
  pageCount,
  radioValue,
  searchPage,
  region,
  state,
  category,
  searchBy,
  sgId,
  party
) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/page/?${
        radioValue ? `page_type=${radioValue}` : ``
      }${pageCount > 1 ? `&page=${pageCount}` : ``}${
        platform ? `&platform=${platform}` : ``
      }${
        searchPage
          ? searchBy === "name"
            ? `&page_name=${searchPage}`
            : `&page_id_str=${searchPage}`
          : ``
      }${(region === 2 || region === 16 ) ? `&region_type=${region}` : ``}${
        (region !== 0) ? `&object_id=${state}` : ``
      }${category ? `&page_category=${category}` : ``}${sgId?`&smargroup=${sgId}`:``}${party?`&political_party=${party}`:``}`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};
export const getPagesPerSmarGroup = async(sgId,pageCount)=>{
  const response = { data: null, error: null };
  try{
const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/pages/smar_pages_channels?smargroup_id=${sgId}&page=${pageCount > 1 ? `&page=${pageCount}` : ``}`)
      response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response
}
export const getTwitterHandleList = async (
  platform,
  pageCount,
  radioValue,
  searchPage,
  region,
  state,
  category
) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/handles/?${
        pageCount > 1 ? `page=${pageCount}` : ``
      }${searchPage ? `&page_name=${searchPage}` : ``}${
        region === 2 ? `&region_type=${region}` : ``
      }${region !== 0 ? `&object_id=${state}` : ``}${
        category ? `&page_category=${category}` : ``
      }${radioValue ? `page_type=${radioValue}` : ``}`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const getYouTubeChannelList = async (
  platform,
  pageCount,
  radioValue = "External",
  searchPage,
  region,
  state,
  category,
  sgId,
  party
) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/channels/?${
        pageCount > 1 ? `page=${pageCount}` : ``
      }${searchPage ? `&name=${searchPage}` : ``}${
        region === 2 ? `&region_type=${region}` : ``
      }${region !== 0 ? `&object_id=${state}` : ``}${
        category ? `&category=${category}` : ``
      }${sgId?`&smargroup=${sgId}`:``}${party?`&political_party=${party}`:``}`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};
export const getInstaAccountsList = async (
  platform,
  pageCount,
  radioValue = "Business",
  searchPage,
  region,
  state,
  category,
  sgId
) => {
  const response = { data: null, error: null };
  
  try {
    // Build URL parameters using URLSearchParams
    const params = new URLSearchParams();
    
    // Add parameters only if they have values
    if (radioValue) {
      params.append('insta_type', radioValue);
    }
    
    if (pageCount > 1) {
      params.append('page', pageCount);
    }
    
    if (searchPage) {
      params.append('insta_name', searchPage);
    }
    
    if (region === 2) {
      params.append('region_type', region);
    }
    
    if (region !== 0) {
      params.append('object_id', state);
    }
    
    if (category) {
      params.append('insta_category', category);
    }
    
    if (sgId) {
      params.append('smargroup', sgId);
    }

    // Convert params to string and create URL
    const queryString = params.toString();
    const url = `${process.env.REACT_APP_API_PATH}/instagram/${queryString ? `?${queryString}` : ''}`;

    const { data } = await AXIOS.get(url);
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  
  return response;
};
export const getPageDetail = async (platform, id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/pages/${id}?${
        platform ? `platform=${platform}` : ``
      }`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const getPageDetailAll = async (platform, id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/page/${id}?${
        platform ? `platform=${platform}` : ``
      }`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const getTwitterDetail = async (id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/handles/${id}/`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const getYouTubeDetail = async (id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/channels/${id}/`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};
export const getInstagramDetail = async (id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/instagram/${id}/`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};
export const getReportData = async (platform) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/reportdetails/generate/group/24`,
      {
        start_date: "2022-09-28",
        end_date: "2022-09-28",
        report_type: "daily",
      }
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const addPages = async (
  id,
  page_id,
  long_token,
  page_name,
  page_type,
  page_category,
  page_sub_category,
  party,
  platform,
  region_type,
  state,
  country,
  district,
  ac,
  pc,
  smar_groups,
  action
) => {
  const response = {
    status: null,
    message: null,
  };
  let object_id = "";
  if (region_type.name.toLowerCase() === "district") {
    object_id = district.id;
  } else if (region_type.name.toLowerCase() === "ac") {
    object_id = ac.id;
  } else if (region_type.name.toLowerCase() === "pc") {
    object_id = pc.id;
  } else if (region_type.name.toLowerCase() === "state") {
    object_id = state.id;
  } else if (
    region_type.name.toLowerCase() === "nation" ||
    region_type.name.toLowerCase() === "nation"
  ) {
    object_id = 1;
  }
  try {

    if (action === "edit") {
      const { data } = await AXIOS.patch(
        `${process.env.REACT_APP_API_PATH}/page/${page_id}/`,
        {
          page_name: page_name,
          page_category: page_category,
          page_subcategory: page_sub_category,
          political_party: party.id,
          platform: platform,
          region_type: region_type.id,
          object_id: object_id,
          smargroup_set: smar_groups,
          country:country.id
        }
      );
     
      response.status = data;
      response.message = data.message;
    } else {
      const { data } = await AXIOS.post(
        `${process.env.REACT_APP_API_PATH}/page/`,
        {
          page_id: page_id,
          long_token: long_token,
          page_name: page_name,
          page_type: page_type,
          page_category: page_category,
          page_subcategory: page_sub_category,
          political_party: party.id,
          platform: platform,
          region_type: region_type.id,
          object_id: object_id,
          smargroup_set: smar_groups,
          country:country.id
        }
      );
      response.status = data;
      response.message = data.message;
    }
  } catch (err) {
    console.log(err,'data')
    response.error = err.response.data.message;
    response.status= err.response.status;
    response.message = err.response.data[0]
  }
  return response;
};

export const addTwitterHandle = async (
  page_id,
  page_name,
  page_category,
  page_sub_category,
  party,
  region_type,
  state,
  district,
  ac,
  pc,
  smar_groups,
  action
) => {
  const response = {
    status: null,
    message: null,
  };
  let object_id = "";
  if (region_type.name.toLowerCase() === "district") {
    object_id = district.id;
  } else if (region_type.name.toLowerCase() === "ac") {
    object_id = ac.id;
  } else if (region_type.name.toLowerCase() === "pc") {
    object_id = pc.id;
  } else if (region_type.name.toLowerCase() === "state") {
    object_id = state.id;
  } else if (
    region_type.name.toLowerCase() === "national" ||
    region_type.name.toLowerCase() === "nation"
  ) {
    object_id = 1;
  }
  try {
    if (action === "edit") {
      const { data } = await AXIOS.patch(
        `${process.env.REACT_APP_API_PATH}/handles/${page_id}/`,
        {
          handle_id: page_name,
          handle_category: page_category,
          handle_subcategory: page_sub_category,
          political_party: party.id,
          region_type: region_type.id,
          object_id: object_id,
          smargroup_set: smar_groups,
        }
      );
      response.status = data;
      response.message = data.message;
    } else {
      const { data } = await AXIOS.post(
        `${process.env.REACT_APP_API_PATH}/handles/`,
        {
          handle_id: page_name,
          handle_category: page_category,
          handle_subcategory: page_sub_category,
          political_party: party.id,
          region_type: region_type.id,
          object_id: object_id,
          smargroup_set: smar_groups,
        }
      );
      response.status = data;
      response.message = data.message;
    }
  } catch (err) {
    response.error = err.response.data.message;
  }
  return response;
};

export const addYouTubeHandle = async (
  id,
  page_id,
  page_name,
  page_category,
  page_sub_category,
  party,
  region_type,
  state,
  district,
  ac,
  pc,
  country,
  smar_groups,
  action
) => {
  const response = {
    status: null,
    message: null,
  };
  let object_id = "";
  if (region_type.name.toLowerCase() === "district") {
    object_id = district.id;
  } else if (region_type.name.toLowerCase() === "ac") {
    object_id = ac.id;
  } else if (region_type.name.toLowerCase() === "pc") {
    object_id = pc.id;
  } else if (region_type.name.toLowerCase() === "state") {
    object_id = state.id;
  } else if (
    region_type.name.toLowerCase() === "national" ||
    region_type.name.toLowerCase() === "nation"
  ) {
    object_id = 1;
  }
  try {
    if (action === "edit") {
      const { data } = await AXIOS.patch(
        `${process.env.REACT_APP_API_PATH}/channels/${id}/`,
        {
          name: page_name,
          youtube_id: page_id,
          category: page_category,
          subcategory: page_sub_category,
          political_party: party.id,
          region_type: region_type.id,
          object_id: object_id,
          smargroup_set: smar_groups,
          country:country.id
        }
      );
      response.status = data;
      response.message = data.message;
    } else {
      const { data } = await AXIOS.post(
        `${process.env.REACT_APP_API_PATH}/channels/`,
        {
          name: page_name,
          youtube_id: page_id,
          category: page_category,
          subcategory: page_sub_category,
          political_party: party.id,
          region_type: region_type.id,
          object_id: object_id,
          smargroup_set: smar_groups,
          country:country.id
        }
      );
      response.status = data;
      response.message = data.message;
    }
  } catch (err) {
    console.log(err,'data')
    response.error = err.response.data.message;
    response.status= err.response.status;
    response.message = err.response.data[0]
  }
  return response;
};
export const addInstagramHandle =async(id,
  page_id,
  insta_id,
  page_name,
  page_category,
  page_sub_category,
  party,
  region_type,
  state,
  district,
  ac,
  pc,
  country,
  smar_groups,
  action
)=>{
  const response = {
    status: null,
    message: null,
  };
  let object_id = "";
  if (region_type.name.toLowerCase() === "district") {
    object_id = district.id;
  } else if (region_type.name.toLowerCase() === "ac") {
    object_id = ac.id;
  } else if (region_type.name.toLowerCase() === "pc") {
    object_id = pc.id;
  } else if (region_type.name.toLowerCase() === "state") {
    object_id = state.id;
  } else if (
    region_type.name.toLowerCase() === "national" ||
    region_type.name.toLowerCase() === "nation"
  ) {
    object_id = 1;
  }
  try {
    if (action === "edit") {
      const { data } = await AXIOS.put(
        `${process.env.REACT_APP_API_PATH}/instagram/${id}/`,
        {
          insta_name: page_name,
          insta_handles: page_id,
          insta_category: page_category,
          insta_subcategory: page_sub_category,
          political_party: party.id,
          region_type: region_type.id,
          object_id: object_id,
          smargroup_set: smar_groups,
          insta_type:"Business",
          country:country.id,
          insta_id:insta_id===""?0:insta_id,
        }
      );
      response.status = data;
      response.message = data.message;
    } else {
      const { data } = await AXIOS.post(
        `${process.env.REACT_APP_API_PATH}/instagram/`,
        {
          insta_name: page_name,
          insta_handles: page_id,
          insta_category: page_category,
          insta_id:insta_id===""?0:insta_id,
          insta_subcategory: page_sub_category,
          political_party: party.id,
          region_type: region_type.id,
          object_id: object_id,
          smargroup_set: smar_groups,
          insta_type:"Business",
          country:country.id,

        }
      );
      response.status = data;
      response.message = data.message;
    }
  } catch (err) {
    console.log(err,'data')
    response.error = err.response.data.message;
    response.status= err.response.status;
    response.message = err.response.data[0]
  }
  return response;

}
export const getPagesPermission = async (uid) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/pages/${uid}/permission`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const handleBulkPagesUpload = async (file) => {
  const response = { data: null, error: null };
  try {
    const formData = new FormData();
    formData.append("page", file);
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/bulk_pages/`,
      formData
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const handleBulkYtChannelsUpload = async (file) => {
  const response = { data: null, error: null };
  try {
    const formData = new FormData();
    formData.append("yt", file);
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/bulk_yt_channels/`,
      formData
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};
export const handleBulkInstaAccountUpload = async (file) => {
  const response = { data: null, error: null };
  try {
    const formData = new FormData();
    formData.append("insta", file);
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/bulk_insta_handles/`,
      formData
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};