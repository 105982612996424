import AXIOS from "services/axios";

export const getPageLevelAnalysis = async (dateStart, dateStop, frequencyType, pageIds, hashtag,platform,country,generatedReportId) => {
  const response = { data: null, error: null };
  const pageIdsSelected=pageIds.map((pageId)=>{return platform===3?`"${pageId}"`:pageId})
  try {
    let url = `${process.env.REACT_APP_API_PATH}/reportdetails/pagelevelanalysis/?date_start=${dateStart}&date_stop=${dateStop}&page_id=[${pageIdsSelected}]&platform=${platform}`;
    if(country!=''){
      url+=`&country=${country}`
    }
    if (frequencyType !== '') {
      url += `&frequency_type=${frequencyType}`;
    }

    if (hashtag) {
      url += `&hashtag=${encodeURIComponent(hashtag)}`;
    }
    if(generatedReportId){
      url+=`&generated_report_id=${generatedReportId}`
    }

    const { data } = await AXIOS.get(url);
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};
