import AXIOS from "services/axios";
import { reportTypesByCategoryMapping } from "utils/data";

export const getReportsList = async (
  pageCount,
  platform,
  searchReport,
  group
) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/reportdetails/?${
        pageCount > 1 ? `page=${pageCount}` : ``
      }${platform ? `&platform=${platform}` : ``}${
        searchReport ? `&reportname=${searchReport}` : ``
      }${group ? `&SmarGroup=${group}` : ``}`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const getReportDetail = async (id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/reportdetails/${id}`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const generateReports = async (filters, id) => {
  const response = { data: null, error: null };
  try {
    const { data } = AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/reportdetails/generate/${id}`,
      filters
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const addReports = async (
  pk,
  report_name,
  report_category,
  report_sub_category,
  platform,
  reportType,
  regionType,
  state,
  country,
  smar_group,
  action
) => {
  const response = {
    status: null,
    message: null,
  };

  const obj = {
    reportname: report_name,
    reportcategory: report_category,
    reportsubcategory: report_sub_category,
    platform_set:
      reportTypesByCategoryMapping.General === reportType.value
        ? [platform.id]
        : [1, 2, 3, 4],
    report_type: reportType.value,
    region_type: regionType,
    country: country.id,
    smargroup_set: smar_group && smar_group.length > 0 ? smar_group : [],
    state: state.id ? state.id : null,
  };

  try {
    if (action === "edit") {
      const { data } = await AXIOS.patch(
        `${process.env.REACT_APP_API_PATH}/reportdetails/${pk}/`,
        obj
      );

      if (data) {
        response.status = 200;
        response.message = "Report edited successfully!!";
      }
    } else {
      const { data } = await AXIOS.post(
        `${process.env.REACT_APP_API_PATH}/reportdetails/`,
        obj
      );
      if (data) {
        response.status = 201;
        response.message = "Report created!!";
      }
    }
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const getAllGeneratedReports = async (id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/reportdetails/status?report=${
        id ? id : ""
      }&created_on=&start_date=&end_date=`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const getCompleteReport = async (id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/reportdetails/report/${id}`
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const submitSmarDashboardReport = (id, type) => {
  const response = { data: null, error: null };
  try {
    const { data } = AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/reportdetails/download/smardashboard_report/`,
      { report: id, download_type: type }
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};
