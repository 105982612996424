import AXIOS from "services/axios";

export const LogPageSession = async (obj) => {
	const response = { data: null, error: null };
	try {
		const { data } = AXIOS.post(
			`${process.env.REACT_APP_API_PATH}/user_anlytics/page/`,
			obj
		);
		response.data = data;
	} catch (err) {
		response.error = err.response;
	}
	return response;
};