import { useState, createContext } from "react";

export const ModalContext = createContext();

export const ModalProvider = (props) => {
  const [modalKey, setModalKey] = useState(null);
  const [modalProps, setModalProps] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [docReportType,setDocReportType]=useState(null)
  const showModal = (modalKey, props = null, data = null,reportType) => {
    setModalProps(props);
    setModalKey(modalKey);
    setModalData(data);
    setDocReportType(reportType)
  };

  const hideModal = () => {
    setModalKey(null);
    setModalProps(null);
    setModalData(null);
    if(docReportType==="dashboard"){
      window.history.pushState({}, "", `/app/smar-dashboard/generated-reports/`);
    }
  };

  const state = {
    modalKey,
    modalProps,
    modalData,
  };

  return (
    <ModalContext.Provider
      value={{
        modalKey: state.modalKey,
        modalProps: state.modalProps,
        modalData: state.modalData,
        showModal,
        hideModal,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};
