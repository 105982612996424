import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: {
    width: '100%',
    marginTop: 30,
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: '#666666',
    backgroundColor: '#fff',
    minHeight: 30,
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: 10,
  },
  tableRow: {
    flexDirection: 'row',
    minHeight: 35,
    alignItems: 'center',
  },
  rankCell: {
    width: '5%',
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: '10px',
  },
  nameCell: {
    width: '23%',
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: '10px',
  },
  partyCell: {
    width: '15%',
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: '10px',
  },
  dateCell: {
    flex: 1,
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: '10px',
    textAlign: 'center',
  },
  headerText: {
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#000',
    fontFamily: "NewRoboto",
    fontWeight: 400,
  },
  cellText: {
    textAlign: 'center',
    fontSize: '10px',
    color: '#000000',
    fontFamily: "NewRoboto",
    fontWeight: 400,
  },
  title: {
    fontSize: 14,
    marginBottom: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  }
});

const PdfDateRangeTable = ({ data, fileName }) => {
  const showPartyColumn = fileName !== "National Parties - Instagram" && 
                         fileName !== "National Parties - Facebook";

  const formatDateRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    
    const formatDate = (date) => {
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' }).slice(0, 3);
      return `${day} ${month}`;
    };
    
    if(start === end) {
      return formatDate(startDate);
    } else {
      return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }
  };

  // Get unique date ranges
  const dateRanges = [...new Set(data.map(item => `${item.start}|${item.end}`))].sort();
  
  // Get unique names
  const uniqueNames = [...new Set(data.map(item => item.page_name))];
  
  // Create processed data structure
  const processedData = uniqueNames.map(name => {
    // Find first entry for this name to get the rank and party
    const firstEntry = data.find(item => item.page_name === name);
    const row = { 
      name,
      rank: firstEntry?.rank || '-',
      party_name: firstEntry?.party_name || '-' // Add party name
    };
    
    dateRanges.forEach(dateRange => {
      const [start, end] = dateRange.split('|');
      const entry = data.find(item => 
        item.page_name === name && 
        item.start === start && 
        item.end === end
      );
      row[dateRange] = entry ? `${entry.total_posts?.toLocaleString()} | ${entry.total_shares?.toLocaleString()} ` : '0';
    });
    return row;
  });

  // Sort the processed data by rank if available
  const sortedData = processedData.sort((a, b) => {
    if (a.rank === '-' || b.rank === '-') return 0;
    return a.rank - b.rank;
  });

  return (
    <View style={styles.table}>
      {/* Header Row */}
      <View style={styles.tableHeader}>
        <View style={styles.rankCell}>
          <Text style={styles.headerText}>Rank</Text>
        </View>
        <View style={styles.nameCell}>
          <Text style={styles.headerText}>Page Name</Text>
        </View>
        {showPartyColumn && (
          <View style={styles.partyCell}>
            <Text style={styles.headerText}>Party</Text>
          </View>
        )}
        {dateRanges.map((dateRange, index) => {
          const [start, end] = dateRange.split('|');
          return (
            <View key={index} style={styles.dateCell}>
              <Text style={styles.headerText}>
                {formatDateRange(start, end)}
              </Text>
            </View>
          );
        })}
      </View>

      {/* Data Rows */}
      {sortedData.map((row, rowIndex) => (
        <View 
          key={rowIndex} 
          style={[styles.tableRow]}
        >
          <View style={styles.rankCell}>
            <Text style={styles.cellText}>{row.rank}</Text>
          </View>
          <View style={styles.nameCell}>
            <Text style={styles.cellText}>{row.name}</Text>
          </View>
          {showPartyColumn && (
            <View style={styles.partyCell}>
              <Text style={styles.cellText}>{row.party_name}</Text>
            </View>
          )}
          {dateRanges.map((dateRange, colIndex) => (
            <View key={colIndex} style={styles.dateCell}>
              <Text style={styles.cellText}>{row[dateRange]}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};

export default PdfDateRangeTable;