import React from "react";
import { Navigate, useLocation } from "react-router-dom";


const ViewSmarDashboard = React.lazy(() => import("pages/app/smar-dashboard"));

export const SmarDashboardRedirect = ({ tab, setActivateLog }) => {
    const location = useLocation();
    const currentPath = location.pathname;
    // Define valid sub-routes for each tab
    const validSubRoutes = {
      'facebook': [
        '/app/smar-dashboard/facebook', 
       
      ],
      'youtube': [
        '/app/smar-dashboard/youtube', 
     
      ],
      'generated-reports': [
        '/app/smar-dashboard/generated-reports'
      ]
    };
  
    // Check if the current path matches the base route or valid sub-routes
    const isValidRoute = validSubRoutes[tab].some(route => 
      currentPath===route
    );
    // If not a valid route, redirect to base route for the tab
    if (!isValidRoute) {
      return <Navigate to={`/app/smar-dashboard/${tab}/`} replace />;
    }
  
    // Render the dashboard component for valid routes
    return <ViewSmarDashboard tab={tab} setActivateLog={setActivateLog} />;
  };