import React, { useMemo, useState } from 'react';
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CircularProgress from '@mui/material/CircularProgress';
import PdfSmarDashboard from 'components/Pdf/PdfSmarDashboard';
import { Upload } from '@mui/icons-material';
import { submitSmarDashboardReport } from 'apis/componentsAction/reports';

const ExportDropdown = ({ 
  id,
  tabValue, 
  selectedColumns, 
  pdfData, 
  handleDownload, 
  isGenerating, 
  validDate, 
  inProgress, 
  chipLoading, 
  selectedPageYouTube, 
  selectedSmarGroupYouTube, 
  selectedSmarGroupFacebook, 
  selectedPageFacebook,
  csvFile,
  type,
  selectedFrequency
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCSVExport = () => {
    const csvContent = csvFile;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    updateDocumentCount(id,'csv')
    handleClose();
  };

  const isExportDisabled = 
    validDate === false ||
    inProgress === true ||
    chipLoading === true;

  const isPdfExportDisabled = useMemo(() => {
    return isExportDisabled || Object.values(selectedColumns).some(columns => columns.length > 10);
  }, [isExportDisabled, selectedColumns]);
const updateDocumentCount=async(id,type)=>{
const {data,error}= submitSmarDashboardReport(id,type)
if (error) {
  console.error("error occured");
  return;
}
if(data){
  console.log('successful',data)
}
}
  const renderPdfExportButton = ({ loading, error }) => {
    if (error) {
      console.error("Error generating PDF:", error);
      return "Error generating PDF";
    }
    
    const button = (
      <Button
        startIcon={<Upload />}
        disabled={isPdfExportDisabled}
        sx={{textTransform:'capitalize'}}
        onClick={()=>updateDocumentCount(id,'pdf')}
      >
        Export PDF
      </Button>
    );

    return (
      <Tooltip title={isPdfExportDisabled ? "Select up to 10 columns only for each section" : ""} arrow>
        <span>{button}</span>
      </Tooltip>
    );
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="contained"
        disabled={isExportDisabled}
        sx={{textTransform:'capitalize'}}
      >
        Export
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          {pdfData?.length > 0 && !isPdfExportDisabled ? (
            <PDFDownloadLink
              document={
                <PdfSmarDashboard
                  platform={tabValue}
                  selectedColumns={selectedColumns}
                  data={pdfData}
                  selectedFrequency={selectedFrequency}
                  type={type}
                />
              }
              fileName="smar.pdf"
            >
              {({ blob, url, loading, error }) => 
                loading || isGenerating ? (
                  <CircularProgress size={24} />
                ) : (
                  renderPdfExportButton({ loading, error })
                )
              }
            </PDFDownloadLink>
          ) : (
            renderPdfExportButton({ loading: false, error: null })
          )}
        </MenuItem>
        <MenuItem onClick={handleCSVExport}>
          <Button
            startIcon={<Upload />}
            disabled={isExportDisabled}
            sx={{textTransform:'capitalize'}}
          >
            Export CSV
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExportDropdown;